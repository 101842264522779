import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-overlays'
import Button from './Form/Button'
import { faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { useStoreState } from 'easy-peasy'
import useConfirm from '../Hooks/useConfirm'
import '@components/ConfirmDeleteModal.scss'

function ConfirmDeleteModal() {
  const showModal = useStoreState((state) => state.general.confirmNavigation)
  const dialog = useStoreState((state) => state.general.confirmationDialog)
  const defaultUserData = useStoreState((state) => state.users.user)
  const { onConfirm, onCancel } = useConfirm()

  return (
    <Modal
      className="confirm-delete-modal"
      renderBackdrop={() => <div className="backdrop"></div>}
      onHide={onCancel}
      show={showModal}
      autoFocus={false}
    >
      <div>
        <div className="header">
          Remove User
          <FontAwesomeIcon
            onClick={onCancel}
            icon={faTimes}
            style={{ float: 'right', cursor: 'pointer', width: '15px', height: '15px' }}
          />
        </div>
        <div className="content">
          <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
            <p>{dialog}</p>
            <div>
              <p className="name no-margin">
                {' '}
                {defaultUserData?.firstName} {defaultUserData?.lastName}{' '}
              </p>
              <p className="no-margin"> {defaultUserData?.username} </p>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="actions">
            <div className="button no-fill" onClick={onCancel}>
              Cancel
            </div>
            <Button
              label="Remove"
              icon={
                <FontAwesomeIcon
                  class="icon"
                  icon={faTrashAlt}
                  style={{ float: 'left', cursor: 'pointer', paddingRight: '8px' }}
                />
              }
              onClick={onConfirm}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmDeleteModal
