import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FullPageModal from '../Layout/FullPageModal'
import { faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons'
import { useCallback, useEffect, useState } from 'react'
import { STATUS_CLASSES } from '../../Utilities/Constants'
import EquipmentTableSelector from '../EquipmentTableSelector'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { getMonitorPoints } from '../../API/Services/Control'
import MonitorChart from '../MonitorChart'

/**
 * @typedef {object} SelectionPaneParams
 * @property {string[]} activites Array of activity names associated with the event.
 * @property {object[]} equipment Array of equipment valid for selection.
 * @property {React.Dispatch<React.SetStateAction<null>>} onSelectionChange Callback for selection change events.
 * @property {boolean} loading Flag for indicating dependent data is in a loading state.
 */

/**
 * @typedef {object} HeaderParams
 * @property {string} siteName Site name.
 * @property {string} buildingName Building name.
 * @property {number} eventId Event identifier.
 * @property {('NOW' | 'SCHEDULED' | 'COMPLETED' | 'CANCELED')} eventStatus Current event status.
 * @property {Date} eventDate Date of the event start.
 */

/**
 * @typedef {object} EventDetailsModalParams
 * @property {string} sitename Site name.
 * @property {object} event Event data.
 */

const InactiveColor = Object.freeze({
  borderColor: 'rgb(200, 200, 200)',
  backgroundColor: 'rgb(200, 200, 200, 0.5)',
})

const SeriesColors = Object.freeze([
  {
    borderColor: 'rgb(61, 47, 144)',
    backgroundColor: 'rgba(61, 47, 144, 0.5)',
  },
  {
    borderColor: 'rgb(25, 211, 197)',
    backgroundColor: 'rgba(25, 211, 197, 0.5)',
  },
  {
    borderColor: 'rgb(255, 243, 110)',
    backgroundColor: 'rgba(255, 243, 110, 0.5)',
  },
])

/**
 * Generate a breakpoint object.
 * @param {string} name Name for the series segment.
 * @param {{borderColor: string, backgroundColor: string}} seriesColor Object representing the colors to use for the series segment.
 * @param {Date} end End date for the series segment.
 * @returns {{name: string, borderColor: string, backgroundColor: string, end: Date}}
 */
const breakpoint = (name, seriesColor, end) => {
  return {
    name,
    borderColor: seriesColor.borderColor,
    backgroundColor: seriesColor.backgroundColor,
    end,
  }
}

/**
 * Generate series breakpoints for charting.
 * @param {Date} start Start of the event period for the first breakpoint.
 * @param {object[]} actions Array of actions that define the event.
 */
const generateSeriesBreakpoints = (start, actions) => {
  // seed with pre-event period with the inactive color up until the event start
  const results = [breakpoint('Pre-Event', InactiveColor, start)]

  // collect all action events ordered by end time
  const actionEvents = actions.flatMap((action) =>
    action.events.map((event) => ({ actionName: action.name, name: event.name, end: new Date(event.end) }))
  )
  actionEvents.sort((a, b) => a.end.getTime() - b.end.getTime())

  // for every event end add a new breakpoint to the results indexing through the series colors
  results.push(
    ...actionEvents.map((event, i) =>
      breakpoint(`${event.actionName} Step ${i + 1}: ${event.name}`, SeriesColors[i], event.end)
    )
  )

  // add the post-event period with the inactive color until the end of time
  results.push(breakpoint('Post-Event', InactiveColor, new Date('9999-01-01T00:00:00Z')))

  return results
}

/**
 * Selection pane component for picking a single piece of equipment.
 * @param {SelectionPaneParams} params
 */
const SelectionPane = ({ activities, equipment, onSelectionChange, loading }) => {
  return (
    <>
      <div className="legend">
        <h4>Control Activites</h4>
        <div className="row">
          {activities.map((a, i) => (
            <div key={`activity-${i}`} className="row-sm">
              <div className={`icon series${i + 1}`}></div>
              <h5>{a}</h5>
            </div>
          ))}
        </div>
        <EquipmentTableSelector equipment={equipment} onSelectionChange={onSelectionChange} loading={loading} />
      </div>
    </>
  )
}

/**
 * Header component.
 * @param {HeaderParams} params
 */
const Header = ({ siteName, buildingName, eventId, eventStatus, eventDate }) => {
  return (
    <div className="container col no-pad">
      <div>
        {siteName} {' > '} {buildingName}
      </div>
      <div className="container header-row no-pad">
        <h1>Event {eventId}</h1>
        <h5>
          Event Status
          <span style={{ marginLeft: '10px' }} className={`status-badge ${STATUS_CLASSES[eventStatus] || ''}`}>
            {eventStatus}
          </span>
        </h5>
        <h5>Event Start Date: {new Date(eventDate).toLocaleDateString()}</h5>
      </div>
    </div>
  )
}

/**
 * Event details modal.
 * @param {EventDetailsModalParams} params
 */
const EventDetailsModal = ({ siteName, event }) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedEquipment, setSelectedEquipment] = useState(null)
  const [monitorPoints, setMonitorPoints] = useState([])

  const equipmentOptions = useStoreState((state) => state.control.requestEquipment)
  const equipmentOptionsLoading = useStoreState((state) => state.control.requestEquipmentLoading)
  const getEquipmentOptions = useStoreActions((action) => action.control.getRequestEquipment)

  // get equipment options after open modal
  useEffect(() => {
    if (showModal && event.id) {
      getEquipmentOptions(event.id)
    }
  }, [showModal, event])

  // get point options after equipment selection and open modal
  useEffect(() => {
    const fetchMonitorPoints = async () => {
      const eventIds = event.actions.flatMap((action) => action.events.map((event) => event.id))
      const resultTasks = [...new Set(eventIds)].map((eventId) => getMonitorPoints(selectedEquipment[0].id, eventId))
      const monitorResults = await Promise.all(resultTasks)

      // flatten all results and dedup
      const uniqueResults = monitorResults
        .flatMap((monitorResult) => monitorResult.data)
        .filter((monitorPoint, i, self) => i === self.findIndex((x) => x.ID === monitorPoint.ID))

      // sort by point class name and set the result
      setMonitorPoints(uniqueResults.sort((a, b) => a.PointClassName.localeCompare(b.PointClassName)))
    }
    if (showModal && selectedEquipment.length) {
      fetchMonitorPoints()
    }
  }, [selectedEquipment, event])

  /**
   * Clear monitor points when no selected equipment.
   */
  useEffect(() => {
    if (selectedEquipment?.length === 0) {
      setMonitorPoints([])
    }
  }, [selectedEquipment, setMonitorPoints])

  const toggleModal = useCallback(() => {
    setShowModal((prevState) => !prevState)
  }, [setShowModal])

  return (
    <>
      <button
        className="icon detail"
        onClick={() => {
          setShowModal(true)
        }}
      >
        <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
      </button>
      <FullPageModal
        show={showModal}
        onHide={toggleModal}
        title={
          <Header
            siteName={siteName}
            buildingName={event.buildingName}
            eventId={event.id}
            eventStatus={event.status}
            eventDate={event.start}
          />
        }
        isInformational={true}
      >
        <div className="container-base h-full">
          <SelectionPane
            activities={event.actions.flatMap((x) => x.events.map((y, i) => `${x.name}-Step ${i + 1}: ${y.name}`))}
            equipment={equipmentOptions}
            onSelectionChange={setSelectedEquipment}
            loading={equipmentOptionsLoading}
          />
          <div
            style={{
              flexGrow: 1,
              borderLeft: '1px solid #EAECEF',
              overflow: 'auto',
              justifyContent: 'center',
            }}
          >
            {selectedEquipment?.length === 0 && (
              <h1 style={{ textAlign: 'center' }}>Select Equipment to View Charts</h1>
            )}
            {monitorPoints.length > 0 &&
              monitorPoints.map((monitorPoint, i) => (
                <MonitorChart
                  key={`monitor-chart-${i}`}
                  pointId={monitorPoint.ID}
                  pointType={monitorPoint.PointClassName}
                  startDate={new Date(event.start)}
                  endDate={new Date(event.end)}
                  writes={[]}
                  seriesBreakpoints={generateSeriesBreakpoints(new Date(event.start), event.actions)}
                />
              ))}
          </div>
        </div>
      </FullPageModal>
    </>
  )
}

export default EventDetailsModal
