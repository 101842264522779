import { useMemo } from 'react'
import Table from '@components/Table/Table'

const EquipmentTableSelector = ({ equipment, loading = false, onSelectionChange }) => {
  const equipmentTableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        filter: 'fuzzyText',
      },
      {
        Header: 'Type',
        accessor: 'typeName',
        filter: 'fuzzyText',
      },
    ],
    []
  )

  return (
    <Table
      singleSelect
      loading={loading}
      columns={equipmentTableColumns}
      data={equipment}
      onSelectionChange={onSelectionChange}
    />
  )
}

export default EquipmentTableSelector
