import BaseModal from '../BaseModal/BaseModal'

function FullPageModal({ children, show, onHide, title, isInformational }) {
  return (
    <BaseModal show={show} onHide={onHide} title={title} hideButtons={isInformational} className="modal full-page">
      {children}
    </BaseModal>
  )
}

export default FullPageModal
