import { useStoreState, useStoreActions } from 'easy-peasy'
import { faTrashCan, faCirclePlus, faEdit, faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import './Building.scss'
import { getBuildingProgramMap, putEventShift, getEventShifts } from '../../API/Services/Flex'
import BaseModal from '@components/BaseModal/BaseModal'
import EventDetailsModal from '@components/Building/EventDetailsModal'
import DropdownField from '@components/Form/DropdownField'
import TextField from '@components/Form/TextField'
import SiteSelector from '@components/SiteSelector'
import Table from '@components/Table/Table'
import { STATUS_CLASSES, STATUS_CONFIG } from '@utilities/Constants'

const Building = () => {
  // Event cancel modal state
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [cancellationReason, setCancellationReason] = useState('')

  // Event shift modal state
  const [showShiftModal, setShowShiftModal] = useState(false)
  const [compensation, setCompensation] = useState()
  const [initialCompensation, setInitialCompensation] = useState()
  const [loadShift, setLoadShift] = useState()
  const [initialLoadShift, setInitialLoadShift] = useState()
  const [shiftProgram, setShiftProgram] = useState()
  const [initialShiftProgram, setInitialShiftProgram] = useState()
  const [shiftProgramOptions, setShiftProgramOptions] = useState([])
  const [shiftFormReady, setShiftFormReady] = useState(false)
  const [shiftFormLoading, setShiftFormLoading] = useState(false)

  // Application state
  const cancelEventLoading = useStoreState((state) => state.control.cancelEventLoading)
  const selectedBuilding = useStoreState((state) => state.building.selected)
  const selectedSite = useStoreState((state) => state.site.selected)
  const eventOptions = useStoreState((state) => state.control.eventOptions)
  const programOptions = useStoreState((state) => state.control.programOptions)
  const buildingOptions = useStoreState((state) => state.building.buildingOptions)

  // Application state actions
  const getEvents = useStoreActions((action) => action.control.getEvents)
  const buildingSelector = useStoreActions((action) => action.building.setSelected)
  const cancelEvent = useStoreActions((action) => action.control.cancelEvent)
  const getPrograms = useStoreActions((action) => action.control.getPrograms)

  const formattedBuildingOptions = useMemo(
    () => buildingOptions?.map((x) => ({ value: x.id, label: x.name })) ?? [],
    [buildingOptions]
  )

  function formatDate(dateString) {
    const date = new Date(dateString)

    if (isNaN(date.getTime())) {
      return ''
    }

    const options = {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    }

    return new Intl.DateTimeFormat('en-US', options).format(date)
  }

  useEffect(() => {
    if (programOptions.length == 0) {
      getPrograms()
    }
  }, [])

  const cancelEventHandler = async () => {
    await cancelEvent({ id: selectedEvent.id, reason: cancellationReason })
    // reload events
    await getEvents({
      ...(selectedSite?.value && { sid: [selectedSite?.value] }),
      ...(selectedBuilding?.value && { bid: [selectedBuilding?.value] }),
    })
    setShowCancelModal(false)
    setCancellationReason('')
  }

  const saveEventShiftHandler = () => {
    // save the event shift
    setShiftFormReady(false)
    putEventShift(selectedEvent.id, shiftProgram.value, loadShift, compensation)
      .then(() => {
        handleShiftModalClose()
        toast.success('Successfully saved event shift data!')
      })
      .catch(() => {
        toast.error('Failed to save event shift data.')
      })

    // reload events
    getEvents({
      ...(selectedSite?.value && { sid: [selectedSite?.value] }),
      ...(selectedBuilding?.value && { bid: [selectedBuilding?.value] }),
    })
  }

  const filterProgramOptions = async (buildingId) => {
    setShiftProgramOptions([])

    const { data } = await getBuildingProgramMap([buildingId])
    const programIds = data[buildingId]

    setShiftProgramOptions(
      programOptions.filter((p) => programIds.includes(p.id)).map((p) => ({ label: p.name, value: p.id }))
    )
  }

  const populateShiftForm = async (eventId, programId) => {
    const { data } = await getEventShifts([eventId], [programId]).catch(() => {
      toast.error('Failed to fetch event shift data.')
    })

    if (data[0]) {
      const { units, compensation } = data[0]
      const stringUnits = units.toString()
      const stringCompensation = compensation.toString()
      setInitialLoadShift(stringUnits)
      setInitialCompensation(stringCompensation)
      setLoadShift(stringUnits)
      setCompensation(stringCompensation)
      setInitialShiftProgram(shiftProgramOptions[0])
      setShiftProgram(shiftProgramOptions[0])
    }
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Event ID',
        accessor: 'id',
      },
      {
        Header: 'Creator',
        accessor: 'createdBy',
      },
      {
        Header: 'Start',
        accessor: 'start',
        Cell: ({ value }) => {
          return <span>{formatDate(value ?? '')}</span>
        },
        disableFilters: true,
      },
      {
        Header: 'End',
        accessor: 'end',
        Cell: ({ value }) => {
          return <span>{formatDate(value ?? '')}</span>
        },
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => {
          return <span className={`status-badge ${STATUS_CLASSES[value] || ''}`}>{value}</span>
        },
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => {
          const config = STATUS_CONFIG[row.original.status]
          return (
            <>
              {config?.allowDelete && (
                <button
                  className="icon delete"
                  onClick={() => {
                    setSelectedEvent(row.original)
                    setShowCancelModal(true)
                  }}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
              )}
              {config?.allowShift && (
                <button
                  className="icon edit"
                  onClick={() => {
                    setSelectedEvent(row.original)
                    setShowShiftModal(true)
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
              )}
            </>
          )
        },
        disableFilters: true,
      },
      {
        Header: '',
        accessor: 'details',
        Cell: ({ row }) => <EventDetailsModal siteName={selectedSite?.label} event={row.original} />,
        disableFilters: true,
      },
    ],
    []
  )

  useEffect(() => {
    // get event even without params
    getEvents({
      ...(selectedSite?.value && { sid: [selectedSite?.value] }),
      ...(selectedBuilding?.value && { bid: [selectedBuilding?.value] }),
    })
  }, [selectedSite, selectedBuilding])

  useEffect(() => {
    if (showShiftModal) {
      filterProgramOptions(selectedEvent.buildingId)
    }
  }, [selectedEvent, showShiftModal])

  useEffect(() => {
    if (showShiftModal && shiftProgram) {
      setShiftFormLoading(true)
      populateShiftForm(selectedEvent.id, shiftProgram.value).then(() => {
        setShiftFormLoading(false)
      })
    }
  }, [shiftProgram, showShiftModal])

  useEffect(() => {
    const regex = new RegExp('[+-]?([0-9]*[.])?[0-9]+')
    if (
      regex.test(compensation) &&
      regex.test(loadShift) &&
      shiftProgram?.value > 0 &&
      (compensation !== initialCompensation || loadShift !== initialLoadShift || shiftProgram !== initialShiftProgram)
    ) {
      setShiftFormReady(true)
    } else {
      setShiftFormReady(false)
    }
  }, [compensation, loadShift, shiftProgram])

  const handleCancelModalClose = () => {
    setShowCancelModal(false)
    setSelectedEvent(null)
    setCancellationReason('') // Reset the reason when closing the modal
  }

  const handleShiftModalClose = () => {
    setShowShiftModal(false)
    setSelectedEvent(null)
    setInitialCompensation()
    setInitialLoadShift()
    setCompensation()
    setLoadShift()
    setShiftProgram()
    setShiftFormReady(false)
  }

  // Initial sorting
  const initialSort = [{ id: 'start', desc: true }]

  return (
    <div className="App-submodule" style={{ minHeight: '300px' }}>
      <div className="App-submodule-header">
        <div style={{ display: 'flex' }}>
          <SiteSelector className={'events-dropdowns form-field'} />
          <DropdownField
            label="Building"
            name="selectedBuilding"
            value={selectedBuilding}
            onChange={buildingSelector}
            options={formattedBuildingOptions}
            className="events-dropdowns building-dropdown form-field"
          />
        </div>

        <Link to="/event/scheduler" className="button scheduler-button">
          <FontAwesomeIcon icon={faCirclePlus} width={15} height={15} className="schedule-event-icon" />
          <span>Schedule Event</span>
        </Link>
      </div>
      <div className="row" style={{ alignItems: 'flex-start' }}>
        <div style={{ flexGrow: 1 }}>
          <Table
            singleSelect
            columns={tableColumns}
            data={eventOptions}
            className="event-table"
            disableSelection={true}
            initialSort={initialSort}
          />
        </div>
      </div>
      <BaseModal
        title="Cancel Event"
        show={showCancelModal}
        autoFocus={false}
        loading={cancelEventLoading}
        onSubmit={cancelEventHandler}
        onHide={handleCancelModalClose}
        cancelText="Go Back"
        submitText="Cancel Event"
        submitIcon={faTrashCan}
        showOkButton={false}
        className="building-modal modal"
        submitDisabled={!cancellationReason.trim()}
      >
        <div className="wrapper-content">
          <span style={{ display: 'flex' }}>
            Are you sure you want to cancel the event at this building? Canceling the event will notify all users with
            access to this building via email or SMS.
          </span>
          <span style={{ display: 'flex', margin: '20px 0' }}>You will not be able to undo this.</span>
          <div>
            <div>
              <span className="title">Event:</span> <span>Event {selectedEvent?.id}</span>
            </div>
            <div>
              <span className="title">Building:</span> <span>{selectedEvent?.buildingName}</span>
            </div>
          </div>
          <div className="form-field">
            <div className="title">
              <span>Cancellation Reason*</span>
            </div>
            <textarea
              cols={3}
              rows={3}
              style={{ resize: 'none' }}
              value={cancellationReason}
              onChange={(e) => setCancellationReason(e.target.value)}
            />
          </div>
        </div>
      </BaseModal>
      <BaseModal
        title="Edit Event Shift"
        show={showShiftModal}
        autoFocus={false}
        onSubmit={saveEventShiftHandler}
        onHide={handleShiftModalClose}
        submitIcon={faFloppyDisk}
        className="building-modal modal"
        submitDisabled={!shiftFormReady}
      >
        <div className="wrapper-content">
          <div className="form-information">
            <div className="title">EventId</div>
            <span>{selectedEvent?.id}</span>
          </div>
          <div className="form-information">
            <div className="title">Building</div>
            <span>{selectedEvent?.buildingName}</span>
          </div>
          <div className="form-information">
            <div className="title">Expected to Flex</div>
            <span>{selectedEvent?.totalLoadRequestkWh} kWh</span>
          </div>
          <DropdownField
            required
            label={'Program'}
            options={shiftProgramOptions}
            onChange={(selection) => setShiftProgram(selection)}
            value={shiftProgram}
            isSearchable={false}
          />
          <TextField
            required
            label="Actually Flexed (kWh)"
            name="loadShift"
            initial={initialLoadShift}
            value={loadShift}
            onChange={(e) => setLoadShift(e.target.value)}
            disabled={shiftFormLoading}
          />
          <TextField
            required
            label="Compensation ($)"
            name="compensation"
            initial={initialCompensation}
            value={compensation}
            onChange={(e) => setCompensation(e.target.value)}
            disabled={shiftFormLoading}
          />
        </div>
      </BaseModal>
    </div>
  )
}

export default Building
