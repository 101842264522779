import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'
import * as validator from 'validator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { getUserPartners, postUserPartners } from '../../API/Services/Partner'
import { toast } from 'react-toastify'
import CreateModal from './CreateModal'
import EditModal from '@components/EditModal'
import Button from '@components/Form/Button'
import TextField from '@components/Form/TextField'

import './CreateButton.scss'

const defaultData = {
  displayName: '',
  description: '',
  permissions: [
    {
      resource: 'service',
      values: [],
    },
  ],
}

function CreateButton({ refreshUsers, refreshCertificates, selectedTab }) {
  const [showEditModal, setShowEditModal] = useState(false)
  const [showUserModal, setShowUserModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showExistingModal, setShowExistingModal] = useState(false)
  const [email, setEmail] = useState('')
  const [isUserInCurrentPartner, setIsUserInCurrentPartner] = useState(false)
  const navigate = useNavigate()

  const currentPartner = useStoreState((state) => state.partner.selected?.value)

  const handleEmailSubmit = async () => {
    setIsLoading(true)
    try {
      const { data: partners } = await getUserPartners(email)

      if (partners && partners.length > 0) {
        // Check if user exists in current partner
        const userInCurrentPartner = partners.some((partner) => partner.id === currentPartner)
        setIsUserInCurrentPartner(userInCurrentPartner)
        setShowUserModal(false)
        setShowExistingModal(true)
      } else {
        navigate('/users/add', { state: { email } })
      }
    } catch (err) {
      console.error(err)
      toast.error('Error checking user partners')
    } finally {
      setIsLoading(false)
    }
  }

  const handleAddToPartner = async () => {
    try {
      const { data } = await postUserPartners(email, currentPartner)
      setShowExistingModal(false)

      if (data.affectedRows === 1) {
        toast.success('Partner was successfully associated')
        refreshUsers()
      } else {
        toast.error('There was an error associating the partner')
      }
    } catch (err) {
      console.error(err)
      toast.error('There was an error associating the partner')
    }
  }

  const resetModals = () => {
    setShowUserModal(false)
    setShowExistingModal(false)
    setEmail('')
    setIsLoading(false)
    setIsUserInCurrentPartner(false)
  }

  const toggleModal = useCallback(() => {
    if (selectedTab === 'services') {
      setShowEditModal(!showEditModal && selectedTab === 'services')
    } else if (selectedTab === 'users') {
      setShowUserModal(true)
    }
  }, [setShowEditModal, showEditModal, selectedTab])

  return (
    <>
      <div className="actions" style={{ gap: 'none' }}>
        <Button
          customClassNames="add"
          label="Create New"
          icon={
            <FontAwesomeIcon
              className="icon"
              icon={faPlusCircle}
              style={{ float: 'left', cursor: 'pointer', paddingRight: '8px' }}
            />
          }
          solid={true}
          onClick={toggleModal}
        />
      </div>

      <CreateModal
        refreshCertificates={refreshCertificates}
        show={showEditModal}
        onToggle={() => setShowEditModal(false)}
        defaultModel={defaultData}
      />

      <EditModal
        show={showUserModal}
        onHide={resetModals}
        title="Create New User"
        onSubmit={handleEmailSubmit}
        submitText="NEXT"
        submitDisabled={!email || !validator.isEmail(email)}
        loading={isLoading}
      >
        <div style={{ margin: '20px' }}>
          <p style={{ marginTop: '0px', marginBottom: '20px' }}>Please enter the user email address</p>
          <TextField
            type="email"
            controlled={false}
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email"
            label="Email"
            required
            className="wide-input"
          />
        </div>
      </EditModal>

      <EditModal
        show={showExistingModal}
        onHide={resetModals}
        title={isUserInCurrentPartner ? 'Duplicate User' : 'Add User to Partner'}
        onSubmit={isUserInCurrentPartner ? resetModals : handleAddToPartner}
        submitText={isUserInCurrentPartner ? 'OK' : 'ADD TO PARTNER'}
        showOkButton={isUserInCurrentPartner}
      >
        <div style={{ margin: '20px' }}>
          <p>
            {isUserInCurrentPartner
              ? 'This user already exists in this partner organization.'
              : 'This user already exists within another partner. Do you want to add this user to this partner as well?'}
          </p>
          <div>{email}</div>
        </div>
      </EditModal>
    </>
  )
}

export default CreateButton
