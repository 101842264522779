import Button from '@components/Form/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

/**
 * Actions available for the Add User page
 * @returns array of actions related to the Add User page
 */
export const addUserActions = (hideSave, handleAddUser) => {
  const navigate = useNavigate()

  return [
    <Button
      customClassNames="no-fill"
      label="Cancel"
      onClick={() => {
        navigate(-1)
      }}
    />,
    <Button
      customClassNames="add"
      label="Add User"
      icon={
        <FontAwesomeIcon
          class="icon"
          icon={faPlusCircle}
          style={{ float: 'left', cursor: 'pointer', paddingRight: '8px' }}
        />
      }
      disabled={hideSave}
      solid={true}
      onClick={handleAddUser}
    />,
  ]
}

/**
 * Actions available for the Edit User page
 * @returns array of actions related to the Edit User page
 */
export const editUserActions = (hideSave, handleEditUser, handleDeleteUser) => {
  const navigate = useNavigate()

  return [
    <Button
      customClassNames="no-fill"
      label="Cancel"
      onClick={() => {
        navigate(-1)
      }}
    />,
    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="20" viewBox="0 0 2 20" fill="none">
      <path d="M1 0V20" stroke="#CAD1D6" />
    </svg>,
    <Button
      customClassNames="no-fill"
      label="Remove User"
      icon={
        <FontAwesomeIcon
          class="icon"
          icon={faTrashAlt}
          style={{ float: 'left', cursor: 'pointer', paddingRight: '8px' }}
        />
      }
      onClick={handleDeleteUser}
      style={{ color: '#3D2F90' }}
    />,
    <Button
      customClassNames="save"
      label="Save"
      disabled={hideSave}
      solid={true}
      onClick={handleEditUser}
      style={{ width: '133px' }}
    />,
  ]
}
