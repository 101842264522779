import { apiClient } from '../API'

/**
 * Gets user's partners
 */
export const getUserPartners = (username) => {
  return apiClient.get(`/auth/user/${username}/partner`)
}

/**
 * Add user to partner
 */
export const postUserPartners = (username, partnerId) => {
  return apiClient.post(`/auth/user/${username}/partner`, { partnerId })
}
