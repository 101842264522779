import React, { useCallback, useState } from 'react'
import BaseModal from './BaseModal/BaseModal'
import { useStoreActions, useStoreState } from 'easy-peasy'
import ExpiryDropdown from './ExpiryDropdown'
import Button from './Form/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

/**
 * Modal for regenerating a certificate for a user.
 * @param {object} props
 * @param {string} props.username Username to regenerate certificate for.
 * @returns {React.ReactElement} The regenerate modal.
 */
function RegenerateModal({ username, ...restProps }) {
  // --- LOCAL STATE --
  // modal visibility
  const [showModal, setShowModal] = useState(false)
  // current expiration days selection
  const [expiryDays, setExpiryDays] = useState(null)

  // --- GLOBAL STATE ---
  // regenerate API request loading
  const loading = useStoreState((state) => state.service.createLoading)

  // --- GLOBAL ACTIONS ---
  // regenerate certificate request thunk
  const regenerateCertificate = useStoreActions((actions) => actions.service.regenerate)
  // get certificates request thunk
  const getCertificates = useStoreActions((actions) => actions.service.getRecords)

  /**
   * Callback for toggling modal visibilty.
   */
  const toggleModal = useCallback(() => {
    // invert modal visibility
    setShowModal(!showModal)
  }, [setShowModal, showModal])

  /**
   * Handle submit button click.
   */
  const handleSubmit = () => {
    // execute the regenerate certificate thunk with currently selected username and expiry days
    regenerateCertificate({ username, expiryDays })
      // after successful thunk, download the returned file data
      .then((cert) => {
        if (cert) {
          // construct a blob with the cert data
          const url = window.URL.createObjectURL(new Blob([cert]))
          // create an anchor element to add to dom
          const link = document.createElement('a')
          // set the href of the link to be the blob with cert data
          link.href = url
          // set attributes for the link with the filename and link open type
          link.setAttribute('download', 'certificate-reissued.pem')
          // add the link to the dom
          document.body.appendChild(link)
          // click the link element
          link.click()
          return true
        }
        // no cert data, nothing to do
        return false
      })
      .then((res) => {
        // if success, toggle modal and refresh certificates
        if (res) {
          toggleModal()
          getCertificates()
        }
      })
  }

  return (
    <>
      <Button label="Regenerate Certificate" onClick={toggleModal} />
      <BaseModal
        title="Regenerate Certificate"
        show={showModal}
        onHide={toggleModal}
        autoFocus={false}
        onSubmit={handleSubmit}
        {...restProps}
      >
        {loading && <FontAwesomeIcon pulse icon={faSpinner} className="loading" />}
        <div className="container">
          <ExpiryDropdown value={expiryDays} onChange={(option) => setExpiryDays(option.value)} />
        </div>
      </BaseModal>
    </>
  )
}

export default RegenerateModal
