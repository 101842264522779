import { action, thunk } from 'easy-peasy'
import {
  getBuildings,
  getBuildingIds,
  getEventData,
  getPrograms,
  cancelRequest,
  getRequestEquipment,
} from '../../API/Services/Control'
import { toast } from 'react-toastify'

const Control = {
  buildingOptions: [],
  eventOptions: [],
  programOptions: [],
  requestEquipment: [],
  requestEquipmentLoading: false,
  eventLoading: false,
  cancelEventLoading: false,
  programLoading: false,

  closeEventLoader: action((state) => {
    state.eventLoading = false
  }),

  openEventLoader: action((state) => {
    state.eventLoading = true
  }),

  closeRequestEquipmentLoader: action((state) => {
    state.requestEquipmentLoading = false
  }),

  openRequestEquipmentLoader: action((state) => {
    state.requestEquipmentLoading = true
  }),

  closeCancelEventLoader: action((state) => {
    state.cancelEventLoading = false
  }),

  openCancelEventLoader: action((state) => {
    state.cancelEventLoading = true
  }),

  closeProgramLoader: action((state) => {
    state.programLoading = false
  }),

  openProgramLoader: action((state) => {
    state.programLoading = true
  }),

  setBuildingOptions: action((state, payload) => {
    state.buildingOptions = payload
  }),

  setEventOptions: action((state, payload) => {
    state.eventOptions = payload
  }),

  setProgramOptions: action((state, payload) => {
    state.programOptions = payload
  }),

  setRequestEquipment: action((state, payload) => {
    state.requestEquipment = payload
  }),

  getBuildings: thunk(async (actions, username) => {
    let buildingIds = [],
      buildingResults = []

    try {
      // Get building Ids
      const { data: ids } = await getBuildingIds(username)
      buildingIds = Object.keys(ids)

      // Get building data
      const { data } = await getBuildings(buildingIds)

      buildingResults = data
    } catch (e) {
      console.log(e)
    }

    actions.setBuildingOptions(buildingResults)
  }),

  getRequestEquipment: thunk(async (actions, requestId) => {
    let equipment = []

    try {
      // get request equipment
      const result = await getRequestEquipment(requestId)

      equipment = result.data
    } catch (e) {
      console.log(e)
    }

    actions.setRequestEquipment(equipment)
  }),

  getEvents: thunk(async (actions, filters) => {
    try {
      actions.openEventLoader()
      const { data } = await getEventData(filters)

      const buildingIds = [...new Set(data.map((item) => item.buildingId))]
      // Get building data
      const { data: buildings } = await getBuildings(buildingIds)
      // Structure building object
      const buildingNames = buildings.reduce((acc, item) => {
        if (item.id && item.name) {
          acc[item.id] = item.name
        }
        return acc
      }, {})
      // Adding the names
      const events = data.map((item) => ({ ...item, buildingName: buildingNames[item.buildingId] }))

      actions.setEventOptions(events)
    } catch (e) {
      console.log(e)
    } finally {
      actions.closeEventLoader()
    }
  }),

  cancelEvent: thunk(async (actions, { id, reason }) => {
    try {
      actions.openCancelEventLoader()

      await cancelRequest(id, reason)

      toast.success(`Cancellation confirmed.`)
    } catch (e) {
      toast.error(`Cancellation error.`)
    } finally {
      actions.closeCancelEventLoader()
    }
  }),

  getPrograms: thunk(async (actions) => {
    try {
      actions.openProgramLoader()

      const { data } = await getPrograms()

      actions.setProgramOptions(data.data)
    } catch (e) {
      toast.error(`Error getting program data`)
    } finally {
      actions.closeProgramLoader()
    }
  }),
}

export default Control
